import { NavLink } from "@remix-run/react";
import { forwardRef } from "react";

type Props = React.ComponentProps<typeof NavLink>;

const SmartLink: React.FC<Props> = forwardRef((props, ref) => {
  if (props.to.toString().startsWith("http")) {
    return (
      <a
        ref={ref}
        href={props.to.toString()}
        className={props.className as string}
        target="_blank"
        rel="noopener noreferer"
      >
        {props.children as React.ReactNode}
      </a>
    );
  }
  return <NavLink ref={ref} {...props} />;
});

export default SmartLink;
